import React from "react"
import { Container } from 'react-bootstrap'

const NotFound = () => {
  return (
    <html lang='en'>
    <header></header>
    <body>
        <Container style={{textAlign :"center"}}>
            <h1 className='pt-5' >Error 404 - Page not Found</h1>
        </Container>
    </body>
    <footer>
    <br></br>
    </footer>
    </html>
  )
}

export default NotFound